import React, { useEffect } from 'react';
import { Link, navigate } from 'gatsby';
import Layout from '../components/layout';
import Seo from '../components/seo';

const NotFound = () => {
  useEffect(() => {
    navigate('https://www.analisedeacoes.com');
  }, []);

  return (
    <Layout menu={false} footer={false} page="notfound">
      <Seo
        title="Página não encontrada"
        description="Descomplicamos sua análise fundamentalista, veja análise de ações das empresas da bolsa gratuitamente, além de comparações, agenda de dividendos e mais."
        url="/pagina-nao-encontrada/" />

      <section className={'header'}>
        <div className={'container'}>
          <img src="/images/logo-small-blue.png" alt="Análise de Ações" />
          <h1>Página não encontrada</h1>
        </div>
      </section>

      <section className={'notfound'}>
        <div className={'container'}>
          <p>A página que você está tentando acessar não está disponível ou não existe. Se você acredita que seja um problema, entre em contato através do nosso e-mail contato@analisedeacoes.com</p>
          <Link to="/">Ir para a página inicial</Link>
        </div>
      </section>
    </Layout>
  );
};

export default NotFound;
